import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreezUsersType, CreateUserResponse } from '../models/settings';
import {
  getBreezUsers,
  createBreezUserApi,
  updateBreezUserApi
} from '../apis/settings';

interface TransactionState {
  breezUsers: BreezUsersType[];
  createUserResponse: CreateUserResponse;
  userData: any;
  loading: boolean;
}
const initialState: TransactionState = {
  breezUsers: [] as BreezUsersType[],
  createUserResponse: {} as CreateUserResponse,
  userData: {} as any,
  loading: false
};

export const getBreezUsersList = createAsyncThunk(
  'settings/getBreezUsersList',
  async () => {
    const response = await getBreezUsers();
    return response.data;
  }
);

export const createBreezUser = createAsyncThunk(
  'settings/createBreezUser',
  async (data: any) => {
    const response = await createBreezUserApi(data);
    return response.data;
  }
);

export const updateBreezUser = createAsyncThunk(
  'settings/updateBreezUser',
  async (data: any) => {
    const { email } = data;
    const response = await updateBreezUserApi(email, data);
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {},
    setCreateUserResponse: (state, action) => {
      state.createUserResponse = action.payload;
    },
    setLoggedInUserData: (state, action) => {
      state.userData = action.payload;
    },
    setBreezUsersList: (state, action) => {
      state.breezUsers = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBreezUsersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getBreezUsersList.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.breezUsers = action.payload?.users;
          }
        }
      )
      .addCase(getBreezUsersList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createBreezUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createBreezUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.createUserResponse = action.payload;
          }
        }
      )
      .addCase(createBreezUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateBreezUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateBreezUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.breezUsers = action.payload?.users;
          }
        }
      )
      .addCase(updateBreezUser.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const {
  setCreateUserResponse,
  setLoggedInUserData,
  setBreezUsersList,
  clearResults
} = settingsSlice.actions;
export default settingsSlice.reducer;
