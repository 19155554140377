import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListSubheader,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  useTheme
} from '@mui/material';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { SidebarContext } from 'src/providers/SidebarContext';
import { themeColors } from 'src/core/theme/schemes/NebulaFighterTheme';

interface sidebarmenuprops {
  menuname: string;
  option: sidebarprop[];
}

export interface sidebarprop {
  has_access: boolean;
  pathname: string;
  icon: JSX.Element;
  submenuname: string;
}
export const SidebarMenus = ({ menuname, option }: sidebarmenuprops) => {
  const theme = useTheme();
  const location = useLocation();
  const { closeSidebar } = useContext(SidebarContext);
  const { t } = useTranslation('sidebarmenu');

  const handleListItemClick = (event, index) => {
    closeSidebar();
  };

  return (
    <Box className="MenuWrapper" sx={{ mt: 2 }}>
      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            {t(menuname)}
          </ListSubheader>
        }
        style={{ padding: 0 }}
      >
        <Box className="SubMenuWrapper">
          <List component="div">
            {option.map((item, index) => {
              return (
                <Box key={item.pathname}>
                  {item?.has_access ? (
                    <ListItem
                      component="div"
                      key={item.pathname}
                      sx={{ mb: 2 }}
                      style={
                        `/${item.pathname}` === location?.pathname
                          ? {
                              background: theme.palette.primary.main,
                              borderRadius: 7
                            }
                          : {}
                      }
                    >
                      <ListItemButton
                        disableRipple
                        component={RouterLink}
                        // onClick={closeSidebar}
                        to={`/${item.pathname}`}
                        onClick={(event) => handleListItemClick(event, index)}
                        style={
                          `/${item.pathname}` === location?.pathname
                            ? { color: themeColors.trueWhite }
                            : {}
                        }
                      >
                        <ListItemIcon
                          sx={{ minWidth: 40 }}
                          style={
                            `/${item.pathname}` === location?.pathname
                              ? { color: themeColors.trueWhite }
                              : {}
                          }
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={t(item.submenuname)} />
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    <></>
                  )}
                </Box>
              );
            })}
          </List>
        </Box>
      </List>
    </Box>
  );
};
