import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HumanRuntimeData } from '../models';
import { getUsersIdentityAPI } from '../apis/IdentityInventoryAPI';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface UserIdentityState {
  userIdentity: HumanRuntimeData[];
  loadingUserIdentity: boolean;
  userIdentityDrawerData: {};
  loadingUserIdentityDrawerData: boolean;
  lastEvaluatedKey: LastEvaluatedKey | null;
  uiDrawerlastEvaluatedKey: LastEvaluatedKey | null;
  currentPage: number;
  filter: string;
  totalCount: number;
}
const initialState: UserIdentityState = {
  userIdentity: [],
  loadingUserIdentity: false,
  userIdentityDrawerData: {},
  loadingUserIdentityDrawerData: false,
  lastEvaluatedKey: null,
  uiDrawerlastEvaluatedKey: null,
  currentPage: 0,
  filter: '',
  totalCount: 0
};

export const getUsersIdentity = createAsyncThunk(
  'runtime/getUsersIdentity',
  async (data: any) => {
    const response = await getUsersIdentityAPI(data);
    return response.data;
  }
);

export const UserIdentityInventorySlice = createSlice({
  name: 'UserIdentityInventory',
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setFilter: (state: any, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
    setUsersIdentityDrawerData: (state: any, action: PayloadAction<any>) => {
      state.userIdentityDrawerData = action.payload;
    },
    clearData: (state: any) => {
      state.userIdentity = [];
      state.userIdentityDrawerData = {};
      state.lastEvaluatedKey = null;
      state.uiDrawerlastEvaluatedKey = null;
      state.currentPage = 0;
      state.filter = '';
      state.totalCount = 0;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUsersIdentity.pending, (state) => {
        state.loadingUserIdentity = true;
      })
      .addCase(
        getUsersIdentity.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingUserIdentity = false;
          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d) => {
              let dataObj = { id: d.breez_event_id, ...d };
              if (d?.cloud_account_id)
                dataObj['account_id'] = d?.cloud_account_id;

              return dataObj;
            });
            state.userIdentity = data;
            if (action.payload.last_evaluated_key) {
              state.lastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getUsersIdentity.rejected, (state) => {
        state.loadingUserIdentity = false;
      });
  }
});
export const {
  setCurrentPage,
  setFilter,
  setUsersIdentityDrawerData,
  clearData
} = UserIdentityInventorySlice.actions;
export default UserIdentityInventorySlice.reducer;
