import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HumanRuntimeData, UserData } from '../models';
import { getGroupsIdentityAPI } from '../apis/IdentityInventoryAPI';
import { Config } from 'src/shared/utils/api-request';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface GroupIdentityState {
  groupIdentity: HumanRuntimeData[];
  loadingGroupIdentity: boolean;
  groupIdentityDrawerData: {};
  loadingGroupIdentityDrawerData: boolean;
  lastEvaluatedKey: LastEvaluatedKey | null;
  uiDrawerlastEvaluatedKey: LastEvaluatedKey | null;
  currentPage: number;
  filter: string;
  totalCount: number;
}
const initialState: GroupIdentityState = {
  groupIdentity: [],
  loadingGroupIdentity: false,
  groupIdentityDrawerData: {},
  loadingGroupIdentityDrawerData: false,
  lastEvaluatedKey: null,
  uiDrawerlastEvaluatedKey: null,
  currentPage: 0,
  filter: '',
  totalCount: 0
};

export const getGroupsIdentity = createAsyncThunk(
  'runtime/getGroupsIdentity',
  async (data: any) => {
    const response = await getGroupsIdentityAPI(data);
    return response.data;
  }
);

export const getGroupsIdentityDrawerData = createAsyncThunk(
  'GroupIdentityInventory/getGroupsIdentityDrawerData',
  async (data: any) => {
    const response = await getGroupsIdentityAPI(data);
    return response.data;
  }
);

export const GroupIdentityInventorySlice = createSlice({
  name: 'GroupIdentityInventory',
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setFilter: (state: any, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
    setGroupIdentityDrawerData: (state: any, action: PayloadAction<any>) => {
      state.groupIdentityDrawerData = action.payload;
    },
    clearData: (state: any) => {
      state.groupIdentity = [];
      state.groupIdentityDrawerData = {};
      state.lastEvaluatedKey = null;
      state.uiDrawerlastEvaluatedKey = null;
      state.currentPage = 0;
      state.filter = '';
      state.totalCount = 0;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getGroupsIdentity.pending, (state) => {
        state.loadingGroupIdentity = true;
      })
      .addCase(
        getGroupsIdentity.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingGroupIdentity = false;
          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d) => {
              let dataObj = { id: d.breez_event_id, ...d };
              if (d?.DisplayName) dataObj['GroupName'] = d?.DisplayName;
              if (d?.cloud_account_id)
                dataObj['account_id'] = d?.cloud_account_id;

              return dataObj;
            });
            state.groupIdentity = data;
            if (action.payload.last_evaluated_key) {
              state.lastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getGroupsIdentity.rejected, (state) => {
        state.loadingGroupIdentity = false;
      });
  }
});
export const {
  setCurrentPage,
  setFilter,
  setGroupIdentityDrawerData,
  clearData
} = GroupIdentityInventorySlice.actions;
export default GroupIdentityInventorySlice.reducer;
