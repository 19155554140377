import * as React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { parseAndFormatDateTime } from 'src/shared/utils/common-functions';

export function AcknowledgementPopover({
  ackData,
  openAcknowledgementPopover,
  setOpenAcknowledgementPopover,
  ...props
}) {
  //   const [anchorEl, setOpenAcknowledgementPopover] = React.useState<HTMLButtonElement | null>(
  //     null
  //   );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenAcknowledgementPopover(event.currentTarget);
  };

  const handleClose = () => {
    setOpenAcknowledgementPopover(null);
  };

  const open = Boolean(openAcknowledgementPopover);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openAcknowledgementPopover}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography sx={{ p: 2 }} color={'text.secondary'}>
          Comment:
        </Typography>
        <Typography sx={{ p: 2 }}>{ackData?.comments}</Typography>
      </Box>

      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography sx={{ p: 2 }} color={'text.secondary'}>
          Response:
        </Typography>
        <Typography sx={{ p: 2 }}>{ackData?.response}</Typography>
      </Box>

      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography sx={{ p: 2 }} color={'text.secondary'}>
          Responded By:
        </Typography>
        <Typography sx={{ p: 2 }}>{ackData?.responded_by}</Typography>
      </Box>

      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography sx={{ p: 2 }} color={'text.secondary'}>
          Time:
        </Typography>
        <Typography sx={{ p: 2 }}>
          {props?.formattedDateTime
            ? ackData?.time
            : parseAndFormatDateTime(ackData?.time)}
        </Typography>
      </Box>
    </Popover>
  );
}
