import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../models';
import { getDashboardDataAPI } from '../apis/DashboardApi';

interface DashboardState {
  dashboardData: {};
  loadingDashboardData: boolean;
}
const initialState: DashboardState = {
  dashboardData: {},
  loadingDashboardData: false
};
export const getDashbaordData = createAsyncThunk(
  'dashboard/getDashbaordData',
  async () => {
    const response = await getDashboardDataAPI();
    return response;
  }
);
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getDashbaordData.pending, (state) => {
        state.loadingDashboardData = true;
      })
      .addCase(
        getDashbaordData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingDashboardData = false;
          if (action.payload) {
            state.dashboardData = action.payload;
          }
        }
      )
      .addCase(getDashbaordData.rejected, (state) => {
        state.loadingDashboardData = false;
      });
  }
});

export default dashboardSlice.reducer;
