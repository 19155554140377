import { DB_TYPE } from 'src/shared/constants/constants';
import { apiPost } from 'src/shared/utils/api-request';

export const getAwsAlertsAPI = async (data: any) => {
  const res = await apiPost('alerts', data);
  return res;
};

export const RemediateAlertsAPI = async (alert_id: string) => {
  const res = await apiPost(`alerts/${alert_id}/remediate`);
  return res;
};

export const CloseAlertsAPI = async (alert_id: string) => {
  const res = await apiPost(`alerts/${alert_id}/close`);
  return res;
};

export const investigateAlertDetailsAPI = async (data: any) => {
  const res = await apiPost(`runtime/forensic${DB_TYPE}`, data);
  return res;
};

export const getAlertDetailsAPI = async (data: any) => {
  const alertId = data?.alertId;
  const body = {
    alert_timestamp: data?.alertTimestamp
  };
  const res = await apiPost(`alerts/${alertId}/details`, body);
  return res;
};
