import { TextField as MuiTextField } from '@mui/material';
import { TextFieldProps } from './services/Textfieldinterface';

export const TextField = ({ ...props }: TextFieldProps) => {
  return (
    <MuiTextField
      variant="outlined"
      size="small"
      InputProps={{
        disableUnderline: true,
        sx: { borderRadius: '4px', padding: '12px' }
      }}
      inputProps={{ style: { padding: 0 } }}
      margin="normal"
      hiddenLabel
      FormHelperTextProps={{ style: { fontWeight: 450 } }}
      required
      fullWidth
      {...props}
    />
  );
};
export default TextField;
