import { useState, useEffect } from 'react';
import {
  Typography,
  useTheme,
  Box,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from '@mui/material';
import dayjs from 'dayjs';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab';
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from '@mui/lab/TimelineOppositeContent';
import { getNameFromArn } from 'src/shared/utils/common-functions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store/configure-store';
import { clearInvestigationState } from 'src/modules/Investigation/services/investigation.service';
import { clearInvestigatedAlertDetailsState } from 'src/modules/AwsAlerts/services/awsAlerts.service';
import { INPUT_DATE_TIME_FORMAT } from 'src/shared/constants/constants';
import { timelineDataMapping } from './helper';

export const TimelineView = ({ timelineData, flex }) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [expanded, setExpanded] = useState<string | false>(false);

  // useEffect(() => {
  //   return function cleanup() {
  //     dispatch(clearInvestigationState());
  //     dispatch(clearInvestigatedAlertDetailsState());
  //   };
  // }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderComponent = (title, value) => (
    <Box py={2}>
      <Typography variant="body2" color={'text.secondary'}>
        {title}
      </Typography>
      <Typography variant="h6">{value}</Typography>
    </Box>
  );

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex
        }
      }}
    >
      {timelineData.map((data, index) => (
        <Accordion
          // expanded={expanded === 'panel1'}
          onChange={handleChange(`panel${index}`)}
          key={index}
          disableGutters={true}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              [`& .MuiAccordionSummary-content`]: {
                margin: 0
              }
            }}
          >
            <TimelineItem key={index} sx={{ width: '100%' }}>
              <TimelineOppositeContent color="text.secondary">
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    {dayjs(data.breez_timestamp, INPUT_DATE_TIME_FORMAT).format(
                      'hh:mm A'
                    )}
                  </Typography>
                  <Typography variant="body2" color={'text.secondary'}>
                    {dayjs(data.breez_timestamp, INPUT_DATE_TIME_FORMAT).format(
                      'MM-DD-YYYY'
                    )}
                  </Typography>
                </Box>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index < timelineData.length - 1 && (
                  <TimelineConnector sx={{ backgroundColor: '#8B939F' }} />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Box>
                  <Typography display={'inline'}>
                    {data?.breez_identity_type === 'machine' &&
                    data?.api_name === 'AssumeRole'
                      ? data?.principal_type
                      : getNameFromArn(data?.principal_arn)}
                  </Typography>
                  <Typography color={'text.secondary'} display={'inline'}>
                    {` has performed `}
                  </Typography>
                  <Typography
                    // color={getOperationChipColor(data?.operation)}
                    display={'inline'}
                  >
                    {data?.operation ? data?.operation : ''}
                    {data.resource.toUpperCase() === 'NONE'}
                  </Typography>
                  <Typography color={'text.secondary'} display={'inline'}>
                    &nbsp;({data.api_name})
                  </Typography>

                  <Typography color={'text.secondary'} display={'inline'}>
                    {data.resource.toUpperCase() === 'NONE' ? ' ' : ` on `}
                  </Typography>
                  {data.resource.toUpperCase() === 'NONE' ? null : (
                    <Typography display={'inline'} pr={1}>
                      {data.resource}
                    </Typography>
                  )}
                  <Chip
                    label={data.event_source?.split('.')[0].toUpperCase()}
                    color="primary"
                    size="small"
                  />
                </Box>
              </TimelineContent>
            </TimelineItem>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              sx={{
                bgcolor: theme.palette.background.default
              }}
            >
              {timelineDataMapping(data)?.map((d) => (
                <Grid
                  key={d?.label}
                  item
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  sx={{ overflow: 'auto', wordWrap: 'break-word' }}
                >
                  {renderComponent(d?.label, d?.value)}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Timeline>
  );
};
