import {
  getGeoLocation,
  getNameFromArn
} from 'src/shared/utils/common-functions';

export const timelineDataMapping = (data) => {
  let mapping = [
    {
      label: 'API Name',
      value: data?.api_name
    },
    {
      label: 'API Status',
      value: data?.api_status
    },
    ,
    {
      label: 'Identity Type',
      value: data?.identity_type
    },
    {
      label: 'Role',
      value: data?.role_arn
    },
    {
      label: 'Resource',
      value: data?.resource
    },
    {
      label: 'User',
      value:
        data?.breez_identity_type === 'machine' &&
        data?.api_name === 'AssumeRole'
          ? data?.principal_type
          : getNameFromArn(data?.principal_arn)
    },
    {
      label: 'Region',
      value: data?.cloud_region
    },
    {
      label: 'Cloud Account ID',
      value: data?.cloud_account_id
    },
    {
      label: 'IP Address',
      value: data?.source_ip_address
    },
    {
      label: 'Geo Location',
      value: getGeoLocation(data?.geo_data)
    },
    {
      label: 'Access Key',
      value: data?.access_key_id
    },

    {
      label: 'Role Chain',
      value: data?.role_chain
    }
  ];

  if (data?.breez_identity_type === 'machine') {
    mapping.splice(2, 0, {
      label: 'Principal Type',
      value: data?.principal_type
    });
  }

  if (data?.api_status === 'Failure') {
    mapping = mapping.concat([
      {
        label: 'Error Code',
        value: data?.error_code
      },
      {
        label: 'Error Message',
        value: data?.error_message
      }
    ]);
  }

  if (
    !data?.breez_identity_type ||
    (data?.breez_identity_type && data?.breez_identity_type === 'code')
  ) {
    mapping = mapping.concat([
      {
        label: 'Git User',
        value: data?.git_last_modified_by
      },
      {
        label: 'Git Commit',
        value: data?.git_commit
      },
      {
        label: 'Git Organization',
        value: data?.git_org
      },
      {
        label: 'Git Repository',
        value: data?.git_repo
      },
      {
        label: 'Git File',
        value: data?.git_file
      }
    ]);
  }
  return mapping;
};

// <Box p={2} columnGap={6}>
// {renderComponent('API Calls', data.api_name)}
// {renderComponent('API Status', data.api_status)}
// {data.breez_identity_type === 'machine' &&
//   renderComponent('Principal Type', data.principal_type)}
// {renderComponent('Identity Type', data.identity_type)}
// {renderComponent('Role', data.role_arn)}
// {renderComponent('Resource', data.resource)}
// </Box>
// <Box p={2} columnGap={6}>
// {renderComponent('User', getNameFromArn(data?.principal_arn))}
// {renderComponent('Region', data.cloud_region)}
// {renderComponent('IP Address', data.source_ip_address)}
// {renderComponent('Access Key', data.access_key_id)}
// {renderComponent('Role Chain', data?.role_chain)}
// </Box>
// {(!data?.breez_identity_type ||
// (data?.breez_identity_type &&
//   data?.breez_identity_type === 'code')) && (
// <Box p={2} columnGap={6}>
//   {renderComponent('Git User', data.git_last_modified_by)}
//   {renderComponent('Git Commit', data.git_commit)}
//   {renderComponent('Git Organization', data.git_org)}
//   {renderComponent('Git Repository', data.git_repo)}
//   {renderComponent('Git File', data.git_file)}
// </Box>
